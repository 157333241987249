/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:41318a00-1d9f-4a82-956c-d3d295eb9204",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_s1WWoOEP4",
    "aws_user_pools_web_client_id": "td0gtq47hpjll71l2vdnjv0b3",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3kklhud5wnhjvkarl22eisiaoy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
